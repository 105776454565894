.benefitsContainer{
    background-color: #1E1E1E;
}
.beneficiosTitle{
    width: 75%;
    text-align: start;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 30px ;
    background: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
.textBenef{
    color: #C3C3C3;
    text-align: start;
    font-size: 14px;
    line-height: 18px;
}
.benefitImgCont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.benefitIcon{
    width: 20%;
}
.benefContainer{
    margin-bottom: 3rem;
}
@media screen and (max-width: 768px) {

    .benefitIcon{
        width: 30%;
    }
    .beneficiosTitle{
        width: 100%;
    }
    .benefContainer{
        margin-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}