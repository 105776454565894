@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.bannerTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 60px;
}
.itemContainer{
    height: 600px;
}
.caption {
    padding-top: 100px;
    right: 8% !important;
    left: 8% !important;
}
.carousel-control-next,
.carousel-control-prev {
    width: 6% !important;
}
.headerTitle{
    display: flex;
    align-items: center;
}
.rSideCont{
    padding-left: 50px;
}
.lSideCont{
    display: flex;
    justify-content: end;
}
.btnCotizar{
    background: linear-gradient(102deg, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%) !important;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border: none;
}
.btnServicios{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.conoceBtn{
    color: white;
}

.conoceBtn:hover{
    color: #3e3e3e;
}
.bannerImg{
    width: 90%;
    animation: flotar 2s ease-in-out infinite alternate;
}

@keyframes flotar{
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-15px);
    }
}

@media screen and (max-width: 991px){
    .rSideCont{
        padding: 10px !important;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .bannerTitle{
        font-size: 45px;
    }
    .btnServicios{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .itemContainer{
        height: 660px;
    }
    
}

@media screen and (min-width: 1400px ) and (max-width: 1909px) {
    .itemContainer{
        height: 750px;
    }
}
@media screen and (min-width: 1910px ) and (max-width: 2000px) {
    .itemContainer{
        height: 900px;
    }
}

@media screen and (min-width: 2001px ) and (max-width: 3000px) {
    .itemContainer{
        height: 1200px;
    }
    
}
