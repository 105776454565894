@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');
.projectCol{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.projectCard{
    background-color: #1E1E1E;
    border-radius: 5px;
    border-bottom: 10px solid #8048F5;
    transition: transform 0.3s ease;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}
.projectCard:hover{
    transform: scale(1.2);
}
.projectDesc{
    font-size: 13px;
    color: #C3C3C3;
}
.projectTitle{
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
}
.pCardBody{
    display: flex;
    flex-direction: column;
    padding: 15px !important;
    justify-content: space-between;
}
.pReadMore{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: #8048F5;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}
.pReadMore:hover{
    background-color: #5831ab !important;
    color: white !important;
}
.cardImg{
    border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 991px) {
    .projectCard{
        margin-bottom: 20px;
    }
    .projectContainer{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .projectCard:hover{
        transform: none;
    }
}