@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.footer{
    background-color: #272727;
}
.text-gray{
    color: #C3C3C3;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}
.formContainer{
    background-color: #3A3A3A;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 10px solid #8048F5;
}
.subtitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 22px;
    margin: 0px;
}
.dataContainer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.data{
    margin: 0px;
    margin-left: 10px;
}
.footerIcon{
    width: 30px;
}
.formTitle{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
}
.submitCnt{
    display: flex;
    justify-content: center;
}
.copyright{
    text-align: center;
    color: #A6A6A6;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
    padding-bottom: 10px;
}
.separator{
    border: 1px solid #C3C3C3;
}
.submitBtn{
    background-color: #8048F5;
    border: none;
    padding-left: 25px;
    padding-right: 25px;
}
.form-control{
    font-size: 14px !important      ;
}
.btnContactFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fuenteFooterBtn{
    font-size: 20px;
}
@media screen and (max-width: 991px) {
    .footer{
        padding-left: 12px;
        padding-right: 12px;
    }
    .fuenteFooterBtn{
        margin-top: 10px;
        font-size: 16px;
    }
}