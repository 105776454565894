
.titleContLocation{
    justify-content: start;
}
.aboutParagraphLocation {
    color: #C3C3C3;
    text-align: start;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 200%;
}

.paragraphContainerLocation{
    width: 450px;
    margin-right: auto;
}
.leftContainerL {
    padding-right: 40px !important;
}

.rightContainerL {
    padding-left: 40px !important;
}
@media screen and (max-width: 991px) {
    .rowsContainer{
        display: flex;
        flex-flow: wrap-reverse;
    }
    .aboutParagraphLocation{
        text-align: justify;
    }
    .rightContainerL{
        padding-left: 12px !important;
        display: flex;
        justify-content: center;
    }
    .leftContainerL{
        padding-right: 12px !important;
        display: flex;
        justify-content: center;
    }
    .paragraphContainerLocation{
        padding-left: 12px;
        padding-right: 12px;
        margin: auto;
        width: 100%;
    }
    .locationImg{
        width: 80%;
    }
    .locationImg:hover{
        transform: none;
    }
}
.locationImg {
    width: 100%;
    animation: flotar 2s ease-in-out infinite alternate;
}
.locationImg:hover{
    transform: scale(1.1);
}

@keyframes flotar{
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-15px);
    }
}