@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.navLink {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.logoNav{
    width: 150px;
}

@media screen and (max-width: 991px) {
    .navbar-collapse {
        background: black !important;
        border-radius: 10px !important;
        padding-left: 10px !important;
        margin-top: 10px !important;
    }
}