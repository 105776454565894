.profilePicture{
    border-radius: 100%; /* Ajusta este valor según el redondeo que desees */
    padding: 5px; /* Define el grosor del borde */
    background: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%); /* Degradado en el borde */
    -webkit-mask-image: linear-gradient(#fff 0 0); /* Permite que el degradado se aplique solo al borde */
    -webkit-mask-composite: destination-in;
    mask-composite: exclude;
    display: block;
    width: 40%;
    height: auto;
}

    
.profileName{
    background: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;
    text-align: start;
}
.profileDesc{
    margin: 0 !important;
    font-size: 16px;
    color: #C3C3C3;
    padding-top: 5px;
}
.profileCard{
    margin-top: 15px;
    background-color: #1E1E1E;
    border-radius: 5px;
    border-bottom: 10px solid #8048F5;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}
#perfil{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.techCol{
    padding: 0px;
}
.iconContainer{
    display: flex;
    justify-content: center;
}
.redContainer{
    background-color: #5B25B3;
    border-radius: 10px;
}
.contactText{
    margin: 0;
}
.profileCol{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.profileIcon{
    margin-right: 5px;
    font-size: 16px;
}
.auxText{
    margin-bottom: 0rem !important;
}

.techContainer{
    justify-content: space-between;
    padding: 0px 16px;
}
.techIconProfile{
    width: 100%;
}