@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.blueBg {
    background: linear-gradient(102deg, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%) !important;
    margin-bottom: 100px;
}
.servContainer{
    margin-bottom: 100px;
}
.servTitle {
    text-align: center;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border-bottom: 6px solid white;
    display: inline;
}

.cardIcon {
    width: 30%;
}
.readMore{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-decoration: none;
    color: #8048F5;
    font-size: 14px;
}
.serviceTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: #3A3A3A;
    font-size: 16px;
}
.cardText{
    font-size: 13px;
}
.cardBody{
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    justify-content: space-between;
    height: 100%;
}
.serviceCard{
    height: 100%;
    border-bottom: 10px solid #8048F5;
    background-color: white;
    border-radius: 5px !important;
    transition: transform 0.3s ease;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}
.serviceImage{
    text-align: center;
    width: 80%;
}
.separator{
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
}
.modalTitle{
    text-align: start;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    display: inline;
    background: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}
.btnPurple{
    background-color: #8048F5;
    color: white;
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 2px;
    
}

.btnPurple:hover{
    background-color: #5831ab !important;
    color: white !important;
}

.serviceCard:hover{
    transform: scale(1.2);
}
.services{
    margin-bottom: -5rem;
}

@media screen and (max-width: 991px) {
    .services{
        margin-bottom: 20px;
    }
    .servicesContainer{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 0px !important;
    }
    .blueBg{
        margin-bottom: 0px;
    }
    .servContainer{
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 768px){
    .servicesBg{
        background: linear-gradient(102deg, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%) !important;
    }

    .blueBg{
        background: transparent !important;
    }
    .serviceCard:hover{
        transform: none;
    }
}
.modal-content{
    background-color: #272727;
    
}
.modal-header{
    border: none;
}