.techIcon{
    width: 3em;
    margin-right: 10px;
}
.techCard{
    border-radius: 10px;
    background-color: #1E1E1E;
    border-bottom: 10px solid #8048F5;
}
.lightGrayText{
    color: #ADADAD;
    font-size: 14px;
}
.slider-item{
    transition: transform .8s ease-in-out;
}

.titutloTecnologias{
    font-size: 22px !important;
}
@media screen and (max-width: 575px) {
    .techContainer{
    }
    
}