@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.aboutImg {
    width: 100%;
    transition: transform 0.3s ease;
}
.aboutImg:hover{
    transform: scale(1.1);
}
.titleCont{
    justify-content: end;
}
.aboutTitle {
    text-align: end;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    display: inline;
    background: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;

    border-bottom: 6px solid;
    border-image: linear-gradient(to right, #6B2ECF 23.1%, #8D34EA 43.56%, #A75DE2 62.9%, #BD98EC 83.35%);
    border-image-slice: 1;
}

.aboutParagraph {
    color: #C3C3C3;
    text-align: end;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 200%;
}

.paragraphContainer {
    width: 400px;
    margin-left: auto;
}

.leftContainer {
    padding-right: 40px !important;
}

.rightContainer {
    padding-left: 40px !important;
}

@media screen and (max-width: 991px) {
    .aboutParagraph{
        text-align: justify;
    }
    .titleCont{
        justify-content: start;
    }
    .rightContainer{
        padding-left: 12px !important;
        display: flex;
        justify-content: center;
    }
    .leftContainer{
        padding-right: 12px !important;
    }
    .paragraphContainer{
        padding-left: 12px;
        padding-right: 12px;
        margin: auto;
        width: 100%;
    }
    .aboutImg{
        width: 80%;
    }
    .aboutImg:hover{
        transform: none;
    }
}